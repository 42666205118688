type ProjectItemTitleProps = {
    order: number;
    title: string;
};

const ProjectItemTitle = ({ order, title }: ProjectItemTitleProps) => {
    return (
        <div aria-label="section-title" className={'project-item-title'}>
            <p>0{order.toString()}</p>
            <h2 className={'heading-four-regular'}>{title}</h2>
        </div>
    );
};

export default ProjectItemTitle;
