import { MenuItem } from '../../types';
import SectionDivider from '../mobileDrawer/mobileDrawerContent/SectionDivider';
import { House as HouseIcon } from 'react-bootstrap-icons';

type FooterProps = {
    projectsMenuItems: MenuItem[];
    skillsMenuItems: MenuItem[];
    linkedInUrl: string;
    githubUrl: string;
};

const Footer = ({ projectsMenuItems, skillsMenuItems, linkedInUrl, githubUrl }: FooterProps) => {
    return (
        <div className={'footer-wrapper'}>
            <div className="column-two">
                <a
                    role="heading"
                    className={'heading-four-regular top-right-item'}
                    href={'/Jenny_Bäcklin_Resume.pdf'}
                    download="Resume_JennyBacklin"
                >
                    Download resume
                </a>
                <ul className={'bottom-right-list'}>
                    <li>
                        <p style={{ color: 'black' }}>Contact:</p>
                        <a
                            role="heading"
                            className={'heading-four-regular'}
                            href={linkedInUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            LinkedIn
                        </a>
                    </li>
                    <li>
                        <a
                            role="heading"
                            className={'heading-four-regular'}
                            href={githubUrl}
                            target="_blank"
                            rel="noreferrer"
                        >
                            GitHub
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
};

export default Footer;
