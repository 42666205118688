type SectionDividerProps = {
    title: string;
    color: string;
};

const SectionDivider = ({ title, color }: SectionDividerProps) => {
    return (
        <div aria-label="section-title" className={`section-divider ${color}`}>
            <h2>{title}</h2>
            <span className={`divider divider-${color}`} />
        </div>
    );
};

export default SectionDivider;
