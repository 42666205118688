import { Plus } from 'react-bootstrap-icons';
import { Dash } from 'react-bootstrap-icons';
import { useState } from 'react';
import { format } from 'date-fns';
import { Education, Experience } from '../../../types';
import SectionTitle from '../SectionTitle';

type ResumeProps = {
    educations: Education[];
    experiences: Experience[];
};

const getYear = (date: Date) => {
    const result = format(new Date(date), 'yyyy');
    return result;
};

const Resume = ({ educations, experiences }: ResumeProps) => {
    const [showExperience, setShowExperience] = useState<boolean>(false);
    const [showEducation, setShowEducation] = useState<boolean>(false);

    return (
        <div style={{ backgroundColor: 'white' }}>
            <span
                style={{
                    display: 'block',
                    width: '100%',
                    height: '59px'
                }}
            ></span>
            <SectionTitle index={'03'} title={'Resume'} />
            <article id="skills-3" aria-label="resume" className={'resume-container'}>
                <div className={'resume-section-title'}>
                    <div style={{ display: 'flex' }}>
                        <p>Experience</p>
                        <button onClick={() => setShowExperience(!showExperience)}>
                            {!showExperience ? (
                                <Plus className={'expand-icon'} />
                            ) : (
                                <Dash className={'expand-icon'} />
                            )}
                        </button>
                    </div>
                    <span className={'divider'} />
                    {showExperience && experiences.length > 0
                        ? experiences.map((experience, index) => {
                              let { startDate, endDate, company, subtitle, description } =
                                  experience.attributes;
                              let startYear = getYear(new Date(startDate));
                              let endYear = getYear(new Date(endDate));

                              return (
                                  <div key={index} className={'cv-listing'}>
                                      <p>
                                          {startYear}-{endYear}
                                      </p>
                                      <h3>{company}</h3>
                                      <p>{subtitle}</p>
                                      <p style={{ paddingBottom: '1rem' }}>{description}</p>
                                  </div>
                              );
                          })
                        : null}
                </div>
                <div className={'resume-section-title'}>
                    <div style={{ display: 'flex' }}>
                        <p>Education</p>
                        <button onClick={() => setShowEducation(!showEducation)}>
                            {!showEducation ? (
                                <Plus className={'expand-icon'} />
                            ) : (
                                <Dash className={'expand-icon'} />
                            )}
                        </button>
                    </div>
                    <span className={'divider'} />
                    {showEducation && educations.length > 0
                        ? educations.map((education) => {
                              let { startDate, endDate, name, sub, description } =
                                  education.attributes;
                              let startYear = getYear(new Date(startDate));
                              let endYear = getYear(new Date(endDate));

                              return (
                                  <div className={'cv-listing'}>
                                      <p>
                                          {startYear}-{endYear}
                                      </p>
                                      <h3>{name}</h3>
                                      <p>{sub}</p>
                                      <p style={{ paddingBottom: '1rem' }}>{description}</p>
                                  </div>
                              );
                          })
                        : null}
                </div>
            </article>
        </div>
    );
};

export default Resume;
