type HeroProps = {
    heroText: { title: string; subTitle: string };
};

const Hero = ({ heroText }: HeroProps) => {
    return (
        <section id={'start-page'} className={'hero'}>
            <div className={'text-wrapper'}>
                <h1 className={'heading-one-regular'}>{heroText.title}</h1>
                <h2 className={'heading-two-regular'}>{heroText.subTitle}</h2>
            </div>
        </section>
    );
};

export default Hero;
