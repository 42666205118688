import { About } from '../../../types';
import SectionTitle from '../SectionTitle';

type BioProps = {
    about: About | null;
};

const Bio = ({ about }: BioProps) => {
    let projectsLinkTitle = 'View projects';
    return (
        <div id="skills-1" className={'bio-section'}>
            <span className={'extra-space-span'}></span>
            <SectionTitle index={'01'} title={'Bio'} />
            <div className={'bio-wrapper'}>
                <div className={'text-section'}>
                    <h4 className={'heading-four-regular'}>Hi!</h4>
                    <p className={'about-text-section paragraph-regular'}>
                        My name is Jenny. I am a frontend developer with a strong eye for design.{' '}
                        <br />
                        <br />I have a background in the field of media-studies and strategic
                        communication, I have run a photography business and I have practiced a
                        bunch of yoga. <br />
                        <br />
                        A mixed background that ties together nicely in working as a frontend
                        developer. I combine my interest for communication strategies, my eye for
                        design and and my problem solving mentality in frontend programming. I love
                        using my skills to create beautiful and functional web applications.
                        <br />
                        <br /> I have my base in Sweden but I love remote work. I think it is a
                        great opportunity to bring people and cultures together cross borders in a
                        global context. Read on to see what skills I have and what my experiences
                        are. Or take a look at some sample projects I have created for this
                        portfolio.
                        <br />
                        <br /> You can also download my resume or contact me directly on LinkedIn.
                    </p>
                    <div className={'project-link-wrapper'}>
                        <a href={'#projects'} className={'heading-four-regular'}>
                            {projectsLinkTitle}
                        </a>
                    </div>
                </div>
                <div className={'img-container'}>
                    <img
                        src="/girl_working_on_laptop.jpg"
                        alt="Girl working on laptop in a relaxed context."
                    />
                </div>
            </div>
        </div>
    );
};

export default Bio;
