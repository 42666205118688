import { MenuItem } from '../../../types';
import IndexList from './IndexList';
import SectionDivider from './SectionDivider';

type MobileDrawerContentProps = {
    skillsMenuItems: MenuItem[];
    projectsMenuItems: MenuItem[];
    onClose: () => void;
    onActiveProjectItem: (id: number) => void;
};

const MobileDrawerContent = ({
    skillsMenuItems,
    projectsMenuItems,
    onClose,
    onActiveProjectItem
}: MobileDrawerContentProps) => {
    return (
        <nav aria-label="navigation-drawer" id={'content-list'}>
            <SectionDivider title={'About me'} color={'black'} />
            <IndexList
                category={'skills'}
                items={skillsMenuItems}
                onClose={onClose}
                onActiveProjectItem={onActiveProjectItem}
            />
            <SectionDivider title={'Explore'} color={'black'} />
            <IndexList
                category={'projects'}
                items={projectsMenuItems}
                onClose={onClose}
                onActiveProjectItem={onActiveProjectItem}
            />
        </nav>
    );
};

export default MobileDrawerContent;
