import { ArrowBarLeft } from 'react-bootstrap-icons';
import { MenuItem } from '../../types';
import MobileDrawerContent from '../mobileDrawer/mobileDrawerContent/MobileDrawerContent';

type SideBarProps = {
    isOpen: boolean;
    onClose: () => void;
    projectsMenuItems: MenuItem[];
    skillsMenuItems: MenuItem[];
    onActiveProjectItem: (id: number) => void;
};

const SideBar = ({
    isOpen,
    onClose,
    projectsMenuItems,
    skillsMenuItems,
    onActiveProjectItem
}: SideBarProps) => {
    return isOpen ? (
        <aside className="sidebar-container">
            <div className={'sidebar-wrapper'}>
                <button className={'close-sidebar-button'} onClick={onClose}>
                    <ArrowBarLeft className={'btn-icon'} />
                </button>
                <div className="sidebar-content">
                    <MobileDrawerContent
                        onClose={() => console.log('')}
                        skillsMenuItems={skillsMenuItems}
                        projectsMenuItems={projectsMenuItems}
                        onActiveProjectItem={onActiveProjectItem}
                    />
                </div>
            </div>
        </aside>
    ) : null;
};

export default SideBar;
