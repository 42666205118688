import SectionTitle from '../SectionTitle';

type SkillListProps = {
    title: string;
    skills: any[];
};

let developMentSkills = [
    { name: 'React.js' },
    { name: 'Javascript' },
    { name: 'HTML5' },
    { name: 'CSS3' },
    { name: 'Typescript' },
    { name: 'Node.js' },
    { name: 'Figma' },
    { name: 'Sass' },
    { name: 'Material UI' },
    { name: 'Photoshop' }
];
let projectSkills = [
    { name: 'GitHub' },
    { name: 'Jira' },
    { name: 'SCRUM' },
    { name: 'Agile' },
    { name: 'DevOps' }
];
let personalSKills = [
    { name: 'Creative' },
    { name: 'Driven' },
    { name: 'Outspoken' },
    { name: 'Knowledge thirsty' }
];
let specialInterestIn = [{ name: 'UI/UX' }, { name: 'Accessability' }];

const SkillList = ({ title, skills }: SkillListProps) => {
    return (
        <div className={'skills-list-container'}>
            <div className={'skills-list-title'}>
                <h4 id="list-title" className={'heading-four-regular'}>
                    {title}
                </h4>
            </div>
            <ul aria-labelledby="list-title" key={title}>
                {skills.length > 0
                    ? skills.map((skill) => (
                          <li key={skill.name}>
                              <p
                                  className={'paragraph-regular'}
                                  style={{
                                      whiteSpace: 'nowrap',
                                      padding: '0 0.5rem',
                                      margin: 0
                                  }}
                              >
                                  {skill.name}
                              </p>
                          </li>
                      ))
                    : null}
            </ul>
        </div>
    );
};

const Skills = () => {
    return (
        <div id="skills-2" className={'skills-section'}>
            <span
                style={{
                    display: 'block',
                    width: '100%',
                    height: '59px'
                }}
            ></span>
            <SectionTitle index={'02'} title={'Skills'} />
            <p className={'sub-title-text paragraph-regular'}>
                Programming is a never ending learning process, I love that. I am eager to try new
                frameworks, libraries and languages. This is my toolbox so far.
            </p>
            <div className={'skills-wrapper'}>
                <SkillList title={'Development'} skills={developMentSkills} />
                <SkillList title={'Project'} skills={projectSkills} />
                <SkillList title={'Special interest in'} skills={specialInterestIn} />
                <SkillList title={'Personal'} skills={personalSKills} />
            </div>
        </div>
    );
};

export default Skills;
