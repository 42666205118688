import { About, Education, Experience } from '../../types';
import Bio from './bio/Bio';
import Resume from './resume/Resume';
import Skills from './skills/Skills';

type AboutProps = {
    about: About | null;
    educations: Education[];
    experiences: Experience[];
};

const AboutMe = ({ about, educations, experiences }: AboutProps) => {
    return (
        <div>
            <Bio about={about} />
            <Skills />
            <Resume educations={educations} experiences={experiences} />
        </div>
    );
};

export default AboutMe;
