import { MenuItem } from '../../types';
import { X } from 'react-bootstrap-icons';
import MobileDrawerContent from './mobileDrawerContent/MobileDrawerContent';

type DrawerProps = {
    isOpen: boolean;
    onClose: () => void;
    projectsMenuItems: MenuItem[];
    skillsMenuItems: MenuItem[];
    githubUrl: string;
    linkedInUrl: string;
    onActiveProjectItem: (id: number) => void;
};

const Drawer = ({
    isOpen,
    onClose,
    projectsMenuItems,
    skillsMenuItems,
    githubUrl,
    linkedInUrl,
    onActiveProjectItem
}: DrawerProps) => {
    return (
        <div className={isOpen ? 'navigation-drawer open' : 'navigation-drawer'}>
            <div className={'drawer-header'}>
                <button onClick={onClose}>
                    <X className={'close-icon'} />
                </button>
            </div>
            <div className={'drawer-content'}>
                <MobileDrawerContent
                    projectsMenuItems={projectsMenuItems}
                    skillsMenuItems={skillsMenuItems}
                    onClose={onClose}
                    onActiveProjectItem={onActiveProjectItem}
                />
                <div className={'links'}>
                    <ul>
                        <li>
                            <a
                                role="heading"
                                className={'heading-four-regular'}
                                href={linkedInUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                LinkedIn
                            </a>
                        </li>
                        <li>
                            <a
                                role="heading"
                                className={'heading-four-regular'}
                                href={githubUrl}
                                target="_blank"
                                rel="noreferrer"
                            >
                                GitHub
                            </a>
                        </li>
                        <li>
                            <a
                                role="heading"
                                className={'heading-four-regular'}
                                href={'/Jenny_Bäcklin_Resume.pdf'}
                                download="Resume_JennyBacklin"
                            >
                                Download resume
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default Drawer;
