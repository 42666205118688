import React, { useState } from 'react';
import { ArrowBarRight } from 'react-bootstrap-icons';

type DesktopHeaderProps = {
    onOpen: () => void;
};
const DesktopHeader = ({ onOpen }: DesktopHeaderProps) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    return (
        <nav>
            <div className={'desktop-header-container'}>
                <div
                    onMouseEnter={() => setShowTooltip(true)}
                    onMouseLeave={() => setShowTooltip(false)}
                >
                    <button onClick={onOpen} className={'sidebar-button'}>
                        <ArrowBarRight className={'btn-icon'} />
                        {showTooltip ? (
                            <div className={'sidebar-btn-tooltip'}>Open sidebar</div>
                        ) : null}
                    </button>
                </div>
            </div>
        </nav>
    );
};

export default DesktopHeader;
