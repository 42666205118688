import { About, Education, Experience, HeroText, MenuItem, Project } from '../types';
import Hero from '../components/hero/Hero';
import MobileHeader from '../components/header/MobileHeader';
import Projects from '../components/projects/Projects';
import { useState } from 'react';
import AboutMe from '../components/about/About';
import Footer from '../components/footer/Footer';
import Drawer from '../components/mobileDrawer/Drawer';
import SideBar from '../components/sideBar/SideBar';
import DesktopHeader from '../components/header/DesktopHeader';

type HomeProps = {
    about: About | null;
    heroText: HeroText;
    educations: Education[];
    experiences: Experience[];
    projects: Project[];
    skillsMenuItems: MenuItem[];
    projectsMenuItems: MenuItem[];
};

const Home = ({
    about,
    heroText,
    educations,
    experiences,
    projects,
    skillsMenuItems,
    projectsMenuItems
}: HomeProps) => {
    const [showMobileDrawer, setShowMobileDrawer] = useState<boolean>(false);
    const [showSideBar, setShowSideBar] = useState<boolean>(true);
    const [activeProjectItem, setActiveProjectItem] = useState<number>(0);

    const handleMobileDrawer = () => {
        setShowMobileDrawer(!showMobileDrawer);
    };

    const handleSideBar = () => {
        setShowSideBar(!showSideBar);
    };

    let githubUrl = about != null ? about.attributes.githubUrl : '';
    let linkedInUrl = about != null ? about.attributes.linkedInUrl : '';

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%'
            }}
        >
            <header id={'top'}>
                <MobileHeader onToggleMenu={handleMobileDrawer} />
                <DesktopHeader onOpen={handleSideBar} />
            </header>
            <div style={{ display: 'flex', width: '100%', margin: 'auto' }}>
                <SideBar
                    isOpen={showSideBar}
                    onClose={handleSideBar}
                    projectsMenuItems={projectsMenuItems}
                    skillsMenuItems={skillsMenuItems}
                    onActiveProjectItem={(id) => setActiveProjectItem(id)}
                />

                <main>
                    <Hero heroText={heroText} />
                    <AboutMe about={about} educations={educations} experiences={experiences} />
                    <Projects projects={projects} activeProjectItem={activeProjectItem} />
                    <Drawer
                        isOpen={showMobileDrawer}
                        onClose={() => handleMobileDrawer()}
                        projectsMenuItems={projectsMenuItems}
                        skillsMenuItems={skillsMenuItems}
                        githubUrl={githubUrl}
                        linkedInUrl={linkedInUrl}
                        onActiveProjectItem={(id) => setActiveProjectItem(id)}
                    />
                </main>
            </div>
            <footer style={{ display: 'flex', width: '100%', margin: 'auto' }}>
                <Footer
                    projectsMenuItems={projectsMenuItems}
                    skillsMenuItems={skillsMenuItems}
                    githubUrl={githubUrl}
                    linkedInUrl={linkedInUrl}
                />
            </footer>
        </div>
    );
};

export default Home;
