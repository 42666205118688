import { List as ListIcon } from 'react-bootstrap-icons';
import { House as HouseIcon } from 'react-bootstrap-icons';

type MobileHeaderProps = {
    onToggleMenu: () => void;
};

const MobileHeader = ({ onToggleMenu }: MobileHeaderProps) => {
    let downloadLinkTitle = 'Download resume';
    return (
        <nav className="mobile-header">
            <div className={'left'}>
                <a href={'#top'}>
                    <HouseIcon className={'icon'} />
                </a>
                <a
                    className={'heading-four-regular'}
                    href={'/Jenny_Bäcklin_Resume.pdf'}
                    download="Resume_JennyBacklin"
                >
                    {downloadLinkTitle}
                </a>
            </div>
            <button onClick={onToggleMenu}>
                <ListIcon className={'icon'} />
            </button>
        </nav>
    );
};

export default MobileHeader;
