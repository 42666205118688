import Title from './ProjectItemTitle';
import ProjectTechnologiesList from './ProjectTechnologiesList';
import { Project } from '../../types';

type ProjectListItemProps = {
    project: Project;
    index: number;
};

type ExternalLinksProps = {
    githubLink: string;
    demoLink: string;
    figmaLink: string;
};

type TextContentProps = {
    skills: any[];
    description: string;
    githubLink: string;
    demoLink: string;
    figmaLink: string;
};

type DemoVideoProps = {
    url: string;
};

export const ExternalLinks = ({ githubLink, demoLink, figmaLink }: ExternalLinksProps) => {
    return (
        <div className={'external-links'}>
            {githubLink !== '' ? (
                <a className={'heading-four-regular'} href={githubLink}>
                    GitHub
                </a>
            ) : null}
            {demoLink !== '' ? (
                <a
                    className={'heading-four-regular'}
                    href={demoLink}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    Live demo
                </a>
            ) : null}
            {figmaLink !== '' ? (
                <a className={'heading-four-regular'} href={figmaLink}>
                    Figma
                </a>
            ) : null}
        </div>
    );
};

const DemoVideo = ({ url }: DemoVideoProps) => {
    return (
        <div className={'demo-container'}>
            {url !== '' ? (
                <video
                    className={'video-player'}
                    poster="/video-poster-paper-co.jpg"
                    width="100%"
                    controls
                    autoPlay
                    muted
                    playsInline
                    webkit-playsInline
                >
                    <source src={url} type="video/mp4" />
                </video>
            ) : (
                <img
                    style={{
                        height: 'auto',
                        width: '100%',
                        objectFit: 'cover',
                        aspectRatio: '16 9'
                    }}
                    src="/undraw_season_change_f99v.svg"
                    alt="coming soon"
                />
            )}
        </div>
    );
};

const TextContent = ({
    skills,
    description,
    githubLink,
    demoLink,
    figmaLink
}: TextContentProps) => {
    return (
        <article className={'text-content'}>
            <div className={'project-tech-and-links'}>
                <ExternalLinks githubLink={githubLink} demoLink={demoLink} figmaLink={figmaLink} />
                <ProjectTechnologiesList title={'Technologies used'} skills={skills} />
            </div>
            <div className={'project-details'}>
                <div className={'project-details-title'}>
                    <h4 id="list-title" className={'heading-four-regular'}>
                        | Project details
                    </h4>
                </div>
                <p className={'paragraph-regular description'}>{description}</p>
            </div>
        </article>
    );
};

const ProjectListItem = ({ project, index }: ProjectListItemProps) => {
    let githubLink = project && project.attributes.github;
    let demoLink = project && project.attributes.demo;
    let figmaLink = project && project.attributes.figma;
    let description = project && project.attributes.description;
    let skills = project && project.attributes.skills.data;
    let demoUrl = project && project.attributes.img.data.attributes.url;

    return (
        <li key={index} aria-label="project-list-item" className={'project-list-item'}>
            <Title order={index + 1} title={project.attributes.title} />

            <DemoVideo url={demoUrl} />
            <TextContent
                skills={skills}
                description={description}
                githubLink={githubLink}
                demoLink={demoLink}
                figmaLink={figmaLink}
            />
        </li>
    );
};

export default ProjectListItem;
