import about from '../local-json/about.json';

const getAbout = async () => {
    // const res = await fetch(`${process.env.REACT_APP_BACKEND}api/about?populate=*`);
    // return res.json();

    return about;
};

export default getAbout;
