type ProjectTechnologiesListProps = {
    title: string;
    skills: any[];
};

const ProjectTechnologiesList = ({ title, skills }: ProjectTechnologiesListProps) => {
    return (
        <div className={'project-technologies-list'}>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                <span />
                <h4
                    id="list-title"
                    className={'heading-four-regular'}
                    style={{ whiteSpace: 'nowrap' }}
                >
                    {title}
                </h4>
            </div>
            <ul role="list" aria-labelledby="list-title" key={title}>
                {skills.length > 0
                    ? skills.map((skill) => (
                          <li key={skill.attributes.title}>
                              <p className={'paragraph-regular'} style={{ whiteSpace: 'nowrap' }}>
                                  {skill.attributes.title}
                              </p>
                          </li>
                      ))
                    : null}
            </ul>
        </div>
    );
};

export default ProjectTechnologiesList;
