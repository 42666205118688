import './App.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import getProjects from './queries/getProjects';
import getAbout from './queries/getAbout';
import Home from './views/Home';
import NotFound from './views/NotFound';
import { About, Education, Experience, HeroText, MenuItem, Project } from './types';
import { QueryClient, QueryClientProvider, useQuery } from 'react-query';

type AppProps = {
    projects: Project[];
    about: About | null;
    educations: Education[];
    experiences: Experience[];
    heroText: HeroText;
    skillsMenuItems: MenuItem[];
    projectsMenuItems: MenuItem[];
};

function App({
    projects,
    about,
    educations,
    experiences,
    heroText,
    skillsMenuItems,
    projectsMenuItems
}: AppProps) {
    return (
        <BrowserRouter>
            <Routes>
                <Route
                    index
                    element={
                        <Home
                            about={about}
                            heroText={heroText}
                            educations={educations}
                            experiences={experiences}
                            projects={projects}
                            skillsMenuItems={skillsMenuItems}
                            projectsMenuItems={projectsMenuItems}
                        />
                    }
                />
                <Route path="*" element={<NotFound />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

const AppDataLayer = () => {
    const {
        data: projectsData,
        isLoading: projectsIsLoading,
        isError: projectsIsError
    } = useQuery('projects', getProjects);

    const {
        data: aboutData,
        isLoading: aboutIsLoading,
        isError: aboutIsError
    } = useQuery('about', getAbout);

    if (projectsIsError || aboutIsError) {
        console.log('error');
    }
    if (projectsIsLoading || aboutIsLoading) {
        console.log('loading');
    }

    let projects: Project[] = projectsData ? projectsData.data : [];

    let about: About | null = aboutData ? aboutData.data : null;

    let educations = aboutData ? aboutData.data.attributes.educations.data : [];
    let experiences = aboutData ? aboutData.data.attributes.experiences.data : [];

    let heroText = { title: 'Hello I am Jenny', subTitle: 'A frontend developer' };

    const skillsMenuItems: MenuItem[] = [
        { id: 1, attributes: { title: 'Bio' } },
        { id: 2, attributes: { title: 'Skills' } },
        { id: 3, attributes: { title: 'Resume' } }
    ];

    const projectsMenuItems: MenuItem[] = [
        { id: 1, attributes: { title: 'Product page' } },
        { id: 2, attributes: { title: 'Recipe app' } },
        { id: 3, attributes: { title: 'Personal portfolio' } }
    ];

    return (
        <App
            projects={projects}
            about={about}
            educations={educations}
            experiences={experiences}
            heroText={heroText}
            skillsMenuItems={skillsMenuItems}
            projectsMenuItems={projectsMenuItems}
        />
    );
};

const AppClientProviderLayer = () => {
    const queryClient = new QueryClient();

    return (
        <QueryClientProvider client={queryClient}>
            <AppDataLayer />
        </QueryClientProvider>
    );
};

export default AppClientProviderLayer;
