import React from 'react';

type SectionTitleProps = {
    index: string;
    title: string;
};

const SectionTitle = ({ index, title }: SectionTitleProps) => {
    return (
        <div aria-label="section-title" className={'section-title-wrapper'}>
            <p style={{ marginRight: '5px' }}>{index}</p>
            <h2 className={'heading-three-regular'}>{title}</h2>
            <span
                style={{
                    borderBottom: '1px solid black',
                    width: '100%',
                    marginLeft: '20px',
                    marginBottom: '10px'
                }}
            />
        </div>
    );
};

export default SectionTitle;
