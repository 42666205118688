import projects from '../local-json/projects.json';
const getProjects = async () => {
    // const res = await fetch(`${process.env.REACT_APP_BACKEND}api/projects?populate=*`);
    // return res.json();

    const res = projects;
    return res;
};

export default getProjects;
