import { useState } from 'react';
import { MenuItem } from '../../../types';

type IndexListProps = {
    items: MenuItem[];
    category: string;
    onClose: () => void;
    onActiveProjectItem: (id: number) => void;
};

const IndexList = ({ items, category, onClose, onActiveProjectItem }: IndexListProps) => {
    const [linkHref, setLinkHref] = useState<string>('');

    const handleClick = (id: number) => {
        if (category === 'projects') {
            setLinkHref('#projects');
            onActiveProjectItem(id - 1);
        }
        if (category !== 'projects') {
            setLinkHref(`#skills-${id}`);
        }
        onClose();
    };
    return (
        <ul aria-label="index-list">
            {items &&
                items.length > 0 &&
                items.map((item, index) => (
                    <li
                        key={item.attributes.title}
                        aria-label="index-item"
                        className={'index-list-row'}
                    >
                        <div className={'index-list-column-one'}></div>
                        <div className={'index-list-column-two'}>
                            <h5 className={'heading-five-regular'}>
                                {category === 'projects' ? `P${index + 1}` : `0${index + 1}`}
                            </h5>
                            <a
                                id={item.id.toString()}
                                onClick={() => handleClick(item.id)}
                                href={linkHref}
                            >
                                <h3 className={'heading-three-regular'}>{item.attributes.title}</h3>
                            </a>
                        </div>
                    </li>
                ))}
        </ul>
    );
};

export default IndexList;
