import { useEffect, useState } from 'react';
import { Project } from '../../types';
import ProjectListItem from './ProjectListItem';
import { ChevronRight } from 'react-bootstrap-icons';
import { ChevronLeft } from 'react-bootstrap-icons';

type ProjectsProps = {
    projects: Project[];
    activeProjectItem: number;
};

type ListNavigationButtonsProps = {
    showNextImage: () => void;
    showPreviousImage: () => void;
};

const ListNavigationButtons = ({
    showPreviousImage,
    showNextImage
}: ListNavigationButtonsProps) => {
    return (
        <div>
            <button className={'buttonLeft'} onClick={showPreviousImage}>
                <ChevronLeft className={'icon'} />
            </button>
            <button className={'buttonRight'} onClick={showNextImage}>
                <ChevronRight className={'icon'} />
            </button>
        </div>
    );
};

const Projects = ({ projects, activeProjectItem }: ProjectsProps) => {
    const [currentImageIndex, setCurrentImageIndex] = useState<number>(activeProjectItem);

    useEffect(() => {
        setCurrentImageIndex(activeProjectItem);
    }, [activeProjectItem]);

    const showNextImage = () => {
        if (currentImageIndex !== projects.length - 1) {
            setCurrentImageIndex(currentImageIndex + 1);
        }

        if (currentImageIndex === projects.length - 1) {
            setCurrentImageIndex(0);
        }
    };

    const showPreviousImage = () => {
        if (currentImageIndex < projects.length + 1) {
            setCurrentImageIndex(currentImageIndex - 1);
        }
        if (currentImageIndex === 0) {
            setCurrentImageIndex(0);
        }
    };

    return (
        <div id="projects" className={'project-slider'}>
            <div className={'slider-container'} aria-label="project-slider">
                <ListNavigationButtons
                    showNextImage={showNextImage}
                    showPreviousImage={showPreviousImage}
                />
                <ul role="list" aria-labelledby="project-section-title">
                    {projects.length > 0 &&
                        projects.map((project, index) =>
                            index === currentImageIndex ? (
                                <ProjectListItem
                                    key={project.attributes.title}
                                    project={project}
                                    index={index}
                                />
                            ) : null
                        )}
                </ul>
            </div>
        </div>
    );
};

export default Projects;
